import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from 'components/pages/CareersPage'
import { Nav } from 'components/nav'
import { LdJsonJobPosting, HeaderLink } from 'components/semanticdata'
import Analytics from 'components/analytics'

const CareerPositionPage = () => (
  <Layout className='bg-primary-1 text-primary-3'>
    <Analytics />
    <SEO
      title='Senior Automation QA Engineer'
      pageUrl='/careers/qa/'
      type='article'
      cardImage='/img/tumbacardjobs1.png'
      description="You don't call yourself just a QA. You have a passion for quality and solid automation experience, Let's talk!"
    />
    <LdJsonJobPosting
      title='Senior Automation QA Engineer'
      description="You don't call yourself just a QA. You have a passion for quality and solid automation experience, Let's talk!"
      publishDate='2023-10-09T09:55:08.000Z'
      minSalary='5000.00'
      maxSalary='6500.00'
    />
    <MainWithMenu className='relative grid'>
      <Header className='z-20 pt-6 pb-24'>
        <HeaderLink className='text-primary-2' />
        <Link to='/careers/'>
          <div className='py-12 text-left'>
            <h1 className='py-4 text-4xl lg:text-5xl text-primary-3 text-shadow'>Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className='z-10 bg-primary-2' decoType={2} before after gridRow={2} />
      <Section className='z-20 lg:mr-48' gridRow={2}>
        <PositionIcon className='w-12 sm:w-20 text-primary-1 fill-current' />
        <h2 className='text-3xl lg:text-4xl text-primary-1'>Senior Automation QA Engineer</h2>
        <div className='mt-4 mb-16 text-lg lg:text-xl tracking-normal'>
          <p className='sm:mt-6 font-normal'>
            You consider yourself a rare breed of IT Professional, an engineer who knows every tiny detail of the
            products you've worked on. You don't call yourself just a QA.{' '}
            <strong className='text-primary-1 font-normal'>You have a passion for quality and solid automation
              experience</strong>.
          </p>
          <p className='sm:mt-6 font-normal'>We do too!</p>
          <p className='sm:mt-6 font-normal'>
            To all common minded peers, <strong className='text-primary-1 font-normal'>let's talk</strong> 'cause we
            offer an opportunity you cannot ignore - to shape the future of mobility!
          </p>
          <ul className='pl-10 mt-2 list-disc font-normal'>
            <li>Help build and validate a distributed compute platform.</li>
            <li>Make the passenger feel safe and secure.</li>
            <li>Control every bit that is going in and out of the vehicle.</li>
          </ul>
          <p className='sm:mt-6 font-normal'>
            <em>What we are primarily looking for is a track record of building things that work and passion to make a
              better product.</em>
          </p>
        </div>
        <div className='grid gap-12 grid-cols-1 lg:grid-cols-2'>
          <div>
            <h3 className='text-xl lg:text-2xl text-primary-1'>Main Responsibilities</h3>
            <div className='mt-4 text-lg lg:text-xl font-normal tracking-normal'>
              <ul className='pl-10 list-disc'>
                <li className='mt-4'>
                  Review functional and design specifications to ensure full understanding of individual deliverables
                  and <strong className='text-primary-1 font-normal'>identify acceptance criteria</strong>.
                </li>
                <li className='mt-4'>
                  Review user requirements documents to <strong className='text-primary-1 font-normal'>ensure that
                  requirements are testable</strong>.
                </li>
                <li className='mt-4'>
                  Transform business rules into <strong className='text-primary-1 font-normal'>test
                  plans</strong> and <strong className='text-primary-1 font-normal'>test specifications</strong>.
                </li>
                <li className='mt-4'>
                  Design test automation frameworks and <strong className='text-primary-1 font-normal'>write automated
                  tests</strong> to conduct complex functional, integration, regression and performance tests.
                </li>
                <li className='mt-4'>
                  Perform <strong className='text-primary-1 font-normal'>manual testing when necessary</strong> as a
                  first step in the creation of automated tests.
                </li>
                <li className='mt-4'>Automate the recording, reporting and analysis of test results.</li>
                <li className='mt-4'>Evaluate the suitability of automation test tools.</li>
                <li className='mt-4'>Identify, document and track bugs.</li>
                <li className='mt-4'>Assist with post-deployment testing in production environments.</li>
                <li className='mt-4'>Contribute to the establishment of QA Automation best practices.</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className='text-xl lg:text-2xl text-primary-1'>Core Requirements</h3>
            <div className='mt-4 text-lg lg:text-xl font-normal tracking-normal'>
              <ul className='pl-10 list-disc'>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>Proficient</strong> in writing and executing detailed
                  test plans.
                </li>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>Proficient</strong> in writing automation tests and
                  building test automation frameworks using Cypress, Selenium or similar tools.
                </li>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>Proficient</strong> in using and writing automated test
                  tools and scripts with Python, JavaScript / TypeScript, shell scripting.
                </li>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>5+ years</strong> experience in Software QA, at least 2
                  of them in QA Automation.
                </li>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>Advanced understanding</strong> of software testing and
                  QA methodologies.
                </li>
                <li className='mt-4'>Ability to write technical documentation.</li>
                <li className='mt-4'>Good understanding of how the Internet works.</li>
                <li className='mt-4'>Knowledge of programming concepts.</li>
                <li className='mt-4'>Analytical mindset with the courage to make judgements and decisions.</li>
                <li className='mt-4'>Self-organized, proactive and good communicator.</li>
                <li className='mt-4'>Able to look through the customer lens.</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position='Senior Automation QA Engineer' active={false} />
      <Nav gridRow={7} className='z-20 text-primary-2' />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
